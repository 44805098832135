.CollectionsHeaderRight {
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 10px 0 rgba(112, 144, 176, 0.16);
    align-content: flex-start;
    margin-right: 1rem;
    .remaining_only_filter{
        font-size: 12px;
        font-family: Gilroy-SemiBold;
        font-weight: normal;
        display: flex;
        align-items: center;
        margin-left: 10px;

        >span{
            margin-right: 5px;
        }
        label{
            margin: 0;
        }
    }
}

.RemainingOnlyFilter{
    font-size: 12px;
    font-family: Gilroy-SemiBold;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin-left: 10px;

    >span{
        margin-right: 5px;
    }
    label{
        margin: 0;
    }
}

.HeaderSubPart {
    margin: 0 5px;

    div {
        &:nth-child(1) {
            color: #BDBEBE;
            font-size: 10px;
            margin-bottom: -2.5px;
            font-family: Gilroy-Medium;
        }

        &:nth-child(2) {
            font-size: 15px;
            font-family: Gilroy-Bold;
        }
    }

    .Green {
        color: #16B216;
    }

    .Grey {
        color: #B6B6B5;
        font-size: 10px;
    }

    .Blue {
        color: #0B8ADA;
    }

    .Red {
        color: #DB6513;
    }
}

.CollectionDropdown {
    margin: 0 20px 0 0;
    position: relative;
    color: #565E66;
    font-size: 14px;
    cursor: pointer;

    img {
        width: 11px;
        margin-left: 10px;
    }
}

.CollectionDropdownOther {
    position: absolute;
}

.ProgressBottom {
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 12px;
    color: #D5D4D5;
    margin-top: 3px;
    font-family: Gilroy-Bold;
}

.ProgressTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-bottom: 3px;
    font-family: Gilroy-Bold;

    .greenText {
        color: #03AA00;
    }

    .redText {
        color: #D80100;
    }
}

.CollectionProgressBar {
    height: 10px;
    width: 260px;
    background-color: #D80100;
    border-radius: 40px;

    .GreyPart {
        background-color: #EEEEED;
        width: 180px;
        height: 10px;
        border-radius: 40px;

        .GreenPart {
            background-color: #03AA00;
            width: 100px;
            height: 10px;
            border-radius: 40px;
        }
    }
}

.tabs {
    height: 28px;
    opacity: 0.45;
    border-radius: 4px;
    background-color: #ededed;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    opacity: unset;
    padding: 3px;
    margin-bottom: 0.4rem;

    button {
        border: none;
        background: none;
        color: #7b7b7b;
    }

    .btn_style {
        border-radius: 2px;
        background-color: #4c8dff;
        color: #ffffff;
        text-align: center;
        padding: 3px;
    }
}

.header_input{
    position: relative;
    input{
        background: inherit;
        background-image: url('../../../../assets/search.svg');
        background-repeat: no-repeat;
        background-position: center left;
        text-indent: 20px;
        border: none;
        /* &:focus{
            background-image: none;
        } */
        //setting table column width from here
        &[name="collectionDate"]{
            width: 80px;
        }
        &[name="payment_method"]{
            width: 90px;
        }
        &[name="owner"]{
            width: 80px;
        }
    }
        &:first-child{
            display: flex;
            margin-right: 10px;
        }
}
.wrapperStyles{
    div{
        background-color: white;
    }
}

.CommonSpacingOntTop{
    margin-top: 2.5rem;
}

.filter_arr_wrapper{
    margin: 10px 35px;
    font-family: Gilroy-SemiBold;
    span{
        border-radius: 4px;
        font-size: 12px;
        padding: 4px;
        background-color: #E8F0FF;
        display: inline-block;
        cursor: pointer;
        margin-left: 10px;

        &:first-child{
            margin-left: 0;
        }
    }
   .clear_all{
    font-size: 12px;
    color: #2F8FFF;
  }
}

.tabsWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .betaSwitch {
        display: flex;
        align-items: center;
        padding: 6px 0 0 5px;
        span {
            font-size: 12px;
            padding-right: 4px;
        }
        label {
            margin: 0;
        }
    }
}
